import firebase from 'firebase/app'

export default async function getAllDataList(payload) {
  const { collection } = payload
  const dataList = []
  try {
    await firebase.firestore().collection(collection)
      .where('displayStatus', '==', 'active')
      .get()
      .then(sn => {
        if (!sn.empty) {
          sn.forEach(doc => {
            dataList.push(doc.data())
          })
        }
      })

    const response = {
      status: 'success',
      data: dataList,
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
